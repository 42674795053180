/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-21 18:02:21
 * @LastEditors: Arvin
 * @LastEditTime: 2021-01-14 16:46:53
 */
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { numberFormat } from '@/plugins/utils'
import IconTitle from '../../../Overview/components/common/iconTitle'

const { mapActions } = createNamespacedHelpers('App/Finance')

export default {
  name: 'Account',
  components: {
    IconTitle
  },
  computed: {
    ...mapGetters([
      'accountInfo',
      'can_buy_combo'
    ]),

    title () {
      if (this.accountInfo.status === 0) {
        return '您好，开始使用前，请先完成账户认证'
      }
      return this.accountInfo.name
    }
  },
  methods: {

    ...mapActions([
      'showMealBuyDialog'
    ]),

    /**
     * 数据格式化
     */
    numberFormat,

    /**
     * 跳转认证页面
     */
    goToAccountConfig () {
      this.$router.push('/app/finance/accountconfig')
    },

    /**
     * 跳转资质详情
     */
    gotoAccountDetail () {
      this.$router.push('/app/finance/accountdetail')
      // this.goToAccountConfig()
    },

    /**
     * 去升级
     */
    goToUpgrade () {
      const {
        name,
        is_new: isNew,
        can_expand: canExpand = false
      } = this.accountInfo

      this.showMealBuyDialog({
        name,
        is_new: isNew,
        can_expand: canExpand,
        combo_buy_type: isNew ? 3 : 1
      })
    }
  }
}
